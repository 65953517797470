import $ from './lib/jquery';
import'bootstrap/dist/js/bootstrap.bundle';
//Lazysizes, lazysizes-bgset-plugin, lazysizes-parent-fit-plugin
function loadJS(u){var r=document.getElementsByTagName("script")[0],s=document.createElement("script");s.src=u;r.parentNode.insertBefore(s,r);}

if(!window.HTMLPictureElement || !('sizes' in document.createElement('img'))){
    loadJS("lazysizes/plugins/respimg/ls.respimg.min");
}
import 'lazysizes/plugins/bgset/ls.bgset';
import Swiper from 'swiper/swiper-bundle';
import 'magnific-popup/dist/jquery.magnific-popup';
import 'lazySizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


$( document ).ready(function() {
    var menu = [];
    var menuEntries = $( ".swiper-slide" )
        .map(function() {
            menu.push( $(this).data("linktext"));
        });
    // var menu = ['Udo Lindenberg', 'Sido', 'Estikay']

    function swiperformobile (swiper) {
        var windowWidth = $(window).width();
        if(windowWidth >= 767){
            swiper.changeDirection('vertical', true);
            swiper.detachEvents();
        }else{
            swiper.changeDirection('horizontal', true);
            swiper.attachEvents();
        }
        swiper.update();
    }

    var swiper = new Swiper(".home-carousel", {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: {
            forceToAxis: true,
        },

        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
                return '<div class="' + className + '"><span class="pagination-bullet"></span><span class="pagination-text">' + (menu[index]) + '</span></div>';
            },
        },
        on: {
            resize: swiperformobile,
            init: swiperformobile
        }
    });


    /* Series */
    const series = new Swiper('.series', {
        // Optional parameters
        autoHeight: true,
        centerInsufficientSlides: true,
        loop: false,
        slidesPerView: '2',
        spaceBetween: 40,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 50,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 60,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 90,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 100,
            },
        },
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            // type: "fraction",
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });

    /* Events */
    const events = new Swiper('.events', {
        // Optional parameters
        autoHeight: false,
        loop: false,
        slidesPerView: '1.2',
        spaceBetween: 20,
        breakpoints: {
            1024: {
                slidesPerView: 2.3,
                spaceBetween: 40
            },
            1400: {
                slidesPerView: 2.4
            },
            2000: {
                slidesPerView: 3.5
            },
        },
    });




    //Mobile Menu
    // $('.mobile-menu-button, .close-menu').on('click',function(){
    //    $('.mobile-menu-overlay').toggle();
    //    $('body').toggleClass('overflow-auto');
    //    $('body').toggleClass('overflow-hidden');
    // });

    /**
     * Name           : MagnificPopup
     * Beschreibung   : PopUps und Lightbox für Artikelbilder, Hinweise und Formulare
     * Parameter      : Klasse des Elements
     * Rückgabe       : -
     * Bibliothek     : jQuery, Magnific Popup (magnific_min.js)
     */
    var groups = {};
    $('.mfp').each(function() {
        var id = parseInt($(this).attr('data-group'), 10);

        if(!groups[id]) {
            groups[id] = [];
        }

        groups[id].push( this );
    });

    $.each(groups, function() {

        $(this).magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            gallery: {
                enabled:true,
                tCounter: '<span class="mfp-counter">%curr% von %total%</span>' // markup of counter
            }
        })

    });

    $('body').on('click','.mfp-content .close-button', function(){
        $.magnificPopup.close();
    });





    /**
     * Name           : Navigation-Highlighting
     * Beschreibung   : set class active to parent, if child is active
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    $('.header-navi ul li:has(li.active)').addClass('active');


    /**
     * Name           : Copyright for images
     * Beschreibung   : overlay copyright id data-copyright exixts
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    var copyrighttext = '';
    var copyrighttextcolor = '';
    if ($(".headerimage, .swiper-slide, .swiper-slide a, .artists-list a figure").data('copyright')) {
        $(".headerimage[data-copyright], .swiper-slide[data-copyright], .swiper-slide a[data-copyright], .artists-list a figure[data-copyright]").each(function() {
            copyrighttext      = $(this).data('copyright');
            copyrighttextcolor = $(this).data('copyright-color');
            if (copyrighttext != '') {
                $(this).append('<div class="copyright"></div>');
                $(this).find('.copyright').html('&copy; ' + copyrighttext).text();
            }
            if (copyrighttextcolor != '') {
                $(this).find('.copyright').css('color', copyrighttextcolor);
            }
        });
    }
});

//Just for triggering swiper for reinit (observer of swiper)
$(document).on('lazybeforeunveil lazyloaded', function(e) {
    if(e.type === 'lazyloaded') {
        $(e.target).hide().show();
        $(e.target).closest('.series').removeClass('lazy-processing').addClass('lazy-complete')
    } else {
        $(e.target).closest('.series').addClass('lazy-processing')
    }
})